import {
  useProjectVerifierControllerRegister,
  RegisterProjectVerifierDTO,
  getProjectVerifierControllerIsProjectVerifierQueryKey,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';

import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export const useApiRegisterProjectVerifierData = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const verifierQueryKey =
    getProjectVerifierControllerIsProjectVerifierQueryKey();

  const { mutate, isLoading } = useProjectVerifierControllerRegister();

  const submitHandler = (values: RegisterProjectVerifierDTO) => {
    mutate(
      { data: { ...values } },
      {
        onSuccess: () => {
          showNotification(
            t('user.projectVerifier.notifications.registeredSuccess'),
            NotificationTypeEnum.Success
          );
          queryClient.invalidateQueries(verifierQueryKey);
        },
        onError: (error: any) => {
          showNotification(
            `${t('user.projectVerifier.notifications.registeredFailure')}:
                ${error?.response?.data?.message || ''}
                `,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };

  return {
    submitHandler,
    isLoading,
  };
};
