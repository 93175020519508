import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { reservesSubmissionForm } from 'libs/organization/logic/src/register/reservesSubmissionForm';
import { useState } from 'react';
import { UploadedFile } from '@energyweb/origin-ui-core';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

export const useReservesSubmissionEffects = (project: ProjectDTO) => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<File[]>([]);

  const { initialValues, validationSchema, fields, validationMode } =
    reservesSubmissionForm(t, project);

  const { handleSubmit } = useForm({
    mode: validationMode,
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const showProjectWells = !!project?.reservesSubmission?.wells?.length;

  const uploadFunction = async (file: File[]) => {
    const fileIndex = documents.findIndex(
      (existingFile: File) => existingFile.name == file[0]?.name
    );
    if (fileIndex === -1) {
      setDocuments((prev: File[]) => [...prev, ...file]);
    }
    return documents;
  };

  const onChange = (file: UploadedFile[]) => {
    if (file[0]?.removed) {
      const files = documents.filter(
        (document: File) => document.name !== file[0].uploadedName
      );
      setDocuments(files);
    }
  };

  return {
    initialValues,
    validationSchema,
    fields,
    validationMode,
    documents,
    project,
    showProjectWells,
    handleSubmit,
    uploadFunction,
    onChange,
  };
};
