import {
  CreateProjectDTO,
  getProjectControllerGetQueryKey,
  useProjectControllerUpdate,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export const useProjectUpdateHandler = (id: number) => {
  const { t } = useTranslation();
  const { mutate } = useProjectControllerUpdate();
  const queryClient = useQueryClient();
  const projectQueryKey = getProjectControllerGetQueryKey(id);

  const submitHandler = (values: CreateProjectDTO) => {
    mutate(
      { id, data: { ...values } },
      {
        onSuccess: () => {
          showNotification(
            t('project.register.notifications.projectUpdateSuccess'),
            NotificationTypeEnum.Success
          );
          queryClient.invalidateQueries(projectQueryKey);
        },
        onError: (error: any) => {
          if (error?.response?.status === 400) {
            showNotification(
              error?.response?.data?.message,
              NotificationTypeEnum.Error
            );
          } else {
            showNotification(
              `${t('project.register.notifications.projectUpdateFailure')}:
                ${error?.response?.data?.message || ''}
                `,
              NotificationTypeEnum.Error
            );
          }
        },
      }
    );
  };

  return { submitHandler };
};
