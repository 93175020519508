import { LightenColor } from '../utils/colors';

const $primaryColor_darkTheme = '#78CC8A';
const $primaryColorDark_darkTheme = LightenColor($primaryColor_darkTheme, -10);
const $primaryColorDim = '#362c45';

const $textColorDefault_darkTheme = '#a8a8a8';
const $simpleTextColor_darkTheme = '#ffffff';
const $inputAutofillColor_darkTheme = '#434343';
const $bodyBackgroundColor_darkTheme = '#2d2d2d';
const $mainBackgroundColor_darkTheme = '#272727';
const $fieldIconColor_darkTheme = LightenColor('#ffffff', -30);

const $primaryColor_lightTheme = '#00D08A';
const $primaryColorDark_lightTheme = LightenColor(
  $primaryColor_lightTheme,
  -10
);
const $textColorDefault_lightTheme = '#78CC8A';
const $simpleTextColor_lightTheme = '#000000';
const $inputAutofillColor_lightTheme = '#cecece';
const $bodyBackgroundColor_lightTheme = 'rgba(160, 211, 171, 0.2)';
const $mainBackgroundColor_lightTheme = '#ffffff';
const $fieldIconColor_lightTheme = LightenColor('#000000', 30);

const $formFieldColor_lightTheme = '#E1DBD8';

const $fontFamilyPrimary = 'Gotham';
const $fontFamilySecondary = 'DIN2014-Regular';
const $fontFamilyDinDemi = 'DIN2014-Demi';
const $fontFamilyAuth = 'FactoriaW00-Book';

const $fontSize = 12;

export type OriginUiThemeVariables = {
  primaryColor: string;
  primaryColorDark: string;
  primaryColorDim: string;
  textColorDefault: string;
  inputAutofillColor: string;
  simpleTextColor: string;
  bodyBackgroundColor: string;
  mainBackgroundColor: string;
  fieldIconColor: string;
  fontFamilyPrimary: string;
  fontFamilySecondary: string;
  fontFamilyDinDemi: string;
  fontFamilyAuth: string;
  fontSize: number;
  formFieldColor: string;
};

export const variables_darkTheme: OriginUiThemeVariables = {
  primaryColor: $primaryColor_darkTheme,
  primaryColorDark: $primaryColorDark_darkTheme,
  primaryColorDim: $primaryColorDim,
  textColorDefault: $textColorDefault_darkTheme,
  simpleTextColor: $simpleTextColor_darkTheme,
  inputAutofillColor: $inputAutofillColor_darkTheme,
  bodyBackgroundColor: $bodyBackgroundColor_darkTheme,
  mainBackgroundColor: $mainBackgroundColor_darkTheme,
  fieldIconColor: $fieldIconColor_darkTheme,
  fontFamilyPrimary: $fontFamilyPrimary,
  fontFamilySecondary: $fontFamilySecondary,
  fontFamilyDinDemi: $fontFamilyDinDemi,
  fontFamilyAuth: $fontFamilyAuth,
  fontSize: $fontSize,
  formFieldColor: $formFieldColor_lightTheme,
};

export const variables_lightTheme: OriginUiThemeVariables = {
  primaryColor: $primaryColor_lightTheme,
  primaryColorDark: $primaryColorDark_lightTheme,
  primaryColorDim: $primaryColorDim,
  textColorDefault: $textColorDefault_lightTheme,
  simpleTextColor: $simpleTextColor_lightTheme,
  inputAutofillColor: $inputAutofillColor_lightTheme,
  bodyBackgroundColor: $bodyBackgroundColor_lightTheme,
  mainBackgroundColor: $mainBackgroundColor_lightTheme,
  fieldIconColor: $fieldIconColor_lightTheme,
  fontFamilyPrimary: $fontFamilyPrimary,
  fontFamilySecondary: $fontFamilySecondary,
  fontFamilyDinDemi: $fontFamilyDinDemi,
  fontFamilyAuth: $fontFamilyAuth,
  fontSize: $fontSize,
  formFieldColor: $formFieldColor_lightTheme,
};
