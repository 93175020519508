import React from 'react';
import { GenericFormProps } from '@energyweb/origin-ui-core';
import { VerifyPhoneFormValues } from '@energyweb/origin-ui-user-data';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export type TUserVerifyPhoneFormLogicArgs = {
  isMutating: boolean;
  isRequesting: boolean;
};

export const useVerifyPhoneFormLogic = ({
  isMutating,
  isRequesting,
}: TUserVerifyPhoneFormLogicArgs): Omit<
  GenericFormProps<VerifyPhoneFormValues>,
  'submitHandler'
> => {
  const { t } = useTranslation();
  return {
    initialValues: { code: '' },
    loading: isMutating,
    validationMode: 'onBlur',
    validationSchema: yup.object().shape({
      code: yup
        .string()
        .matches(/((?=.*[0-9]).{6})/, t('user.verifyPhone.codeValidation'))
        .label(t('user.verifyPhone.code'))
        .required(),
    }),
    fields: [
      {
        label: t('user.verifyPhone.code'),
        name: 'code',
        required: true,
        type: 'text',
      },
    ],
    inputsVariant: 'filled',
    buttonFullWidth: true,
    buttonText: t('general.buttons.submit'),
    buttonWrapperProps: { justifyContent: 'space-between' },
  };
};
