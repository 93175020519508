import {
  RegisterProjectOwnerDTO,
  useProjectOwnerControllerUpdate,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const useApiUpdateProjectOwnerData = (id: number) => {
  const { t } = useTranslation();

  const { mutate } = useProjectOwnerControllerUpdate();
  const navigate = useNavigate();

  const submitHandler = (values: RegisterProjectOwnerDTO) => {
    mutate(
      { id, data: { ...values } },
      {
        onSuccess: () => {
          showNotification(
            t('user.projectOwner.notifications.updatedSuccess'),
            NotificationTypeEnum.Success
          );
          navigate('/organization/register');
        },
        onError: (error: any) => {
          showNotification(
            `${t('user.projectOwner.notifications.updatedFailure')}:
                  ${error?.response?.data?.message || ''}
                  `,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };

  return {
    submitHandler,
  };
};
