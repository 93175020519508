import React, { FC, memo } from 'react';
import { Box, Button, IconButton, Toolbar } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import clsx from 'clsx';
import { TopBarButtonData } from '../TopBar';
import { useStyles } from './DesktopTopBar.styles';
import { ZeroSixLogoBlack, MenuDots } from '@energyweb/origin-ui-assets';

type DesktopTopbarButton = Omit<TopBarButtonData, 'Icon'>;

export interface DesktopTopBarProps {
  buttons: DesktopTopbarButton[];
  toolbarClassName?: string;
}

export const DesktopTopBar: FC<DesktopTopBarProps> = memo(
  ({ buttons, toolbarClassName }) => {
    const classes = useStyles();
    return (
      <Toolbar className={clsx(toolbarClassName, classes.toolbar)}>
        <Box
          sx={{ display: { lg: 'block', xs: 'none' } }}
          className={classes.logobar}
        >
          <IconButton aria-label="open drawer" edge="start">
            <ZeroSixLogoBlack className={classes.logo} />
          </IconButton>
        </Box>
        <Box flexGrow={1} />
        <SvgIcon className={classes.menuIcon} component={MenuDots} />
        <AccountCircleIcon color="primary" className={classes.profileIcon} />
        {buttons
          ?.filter((v) => v.show !== false)
          .map(({ label, onClick, dataCy }) => (
            <Button
              className={classes.button}
              key={label}
              onClick={onClick}
              data-cy={dataCy}
            >
              {label}
            </Button>
          ))}
      </Toolbar>
    );
  }
);

DesktopTopBar.displayName = 'DesktopTopBar';
