import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  form: {
    background: '#FEFFFF',
    boxShadow: '0px 6px 24px rgba(25, 29, 56, 0.16)',
    padding: '20px 35px',
    '& .MuiTable-root': {
      background: '#FFFFFF',
      border: '1px solid #9CC7A6',
      borderRadius: '4px',
      margin: '24px 0',
      '& thead': {
        background: 'rgba(160, 211, 171, 0.4)',
        border: '1px solid #9CC7A6',
      },
      '& tbody > tr': {
        background: '#FFFFFF',
      },
      '& tbody > tr > td': {
        color: '#2B201F',
      },
    },

    '& .MuiFormControl-root': {
      '& label': {
        color: '#2B201F',
      },
    },

    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#AAB0F3',
      },
      '& .Mui-focused fieldset': {
        borderColor: '#AAB0F3',
      },
      '& .MuiInputAdornment-positionStart': {
        width: '100%',
      },
      '& .MuiSelect-select': {
        background: '#FFF',
      },
    },
    '& input, select': {
      border: '1px solid #AAB0F3',
      borderRadius: '4px',
      color: '#757575',
      padding: 15,
    },

    '& .MuiTextField-root': {
      width: '100%',
      marginTop: 10,
    },
    '& fieldset': {
      borderColor: '#AAB0F3',
    },
  },
});
