import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import * as yup from 'yup';
import { TRoyaltyOwnerFormValues } from './types';

export const royaltyOwnerForm: TRoyaltyOwnerFormValues = (
  t,
  project?: ProjectDTO
) => {
  return {
    formTitle: t('project.register.submission'),
    inputsVariant: 'outlined',
    initialValues: {
      name: '',
      email: '',
      ownershipType: '',
      confirmed: false,
      ownershipId: project?.ownership?.id,
    },
    validationSchema: yup.object().shape({
      name: yup
        .string()
        .required()
        .label(t('project.register.royaltyOwner.name')),
      email: yup
        .string()
        .email()
        .required()
        .label(t('project.register.royaltyOwner.email')),
      ownershipType: yup
        .string()
        .required()
        .label(t('project.register.royaltyOwner.ownershipType')),
    }),
    fields: [
      {
        name: 'name',
        label: t('project.register.royaltyOwner.name'),
        required: true,
        inputProps: { ['data-cy']: 'name' },
      },
      {
        name: 'ownershipType',
        label: t('project.register.royaltyOwner.ownershipType'),
        required: true,
        inputProps: { ['data-cy']: 'ownershipType' },
      },
      {
        name: 'email',
        label: t('project.register.royaltyOwner.email'),
        required: true,
        inputProps: { ['data-cy']: 'email' },
      },
    ],
    validationMode: 'onSubmit',
    buttonText: t('general.buttons.save'),
    customStep: true,
  };
};
