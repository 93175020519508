import * as yup from 'yup';
import { TProjectWellForm } from './types';
import { WELL_TYPE_OPTIONS } from '../select-options/projectTypes';

export const projectWellsForm: TProjectWellForm = (t, project) => {
  return {
    formTitle: t('project.register.submission'),
    inputsVariant: 'outlined',
    initialValues: {
      wellName: '',
      wellAPINumber: '',
      wellType: '',
      holeLatitude: null,
      holeLongitude: null,
      reservesSubmissionId: project?.reservesSubmission?.id,
    },
    validationSchema: yup.object().shape({
      wellName: yup
        .string()
        .required()
        .label(t('project.register.reservesSubmission.wellName')),
      wellAPINumber: yup
        .string()
        .length(14)
        .required()
        .label(t('project.register.reservesSubmission.wellAPINumber')),
      wellType: yup
        .string()
        .required()
        .label(t('project.register.reservesSubmission.wellType')),
      holeLatitude: yup
        .number()
        .required()
        .label(t('project.register.reservesSubmission.holeLatitude')),
      holeLongitude: yup
        .number()
        .required()
        .label(t('project.register.reservesSubmission.holeLongitude')),
    }),
    fields: [
      {
        name: 'wellName',
        label: t('project.register.reservesSubmission.wellName'),
        required: true,
        inputProps: { ['data-cy']: 'wellName' },
        placeholder: 'Add Well Name',
        helperText: 'Same as Case Name in Reserve Import Document',
      },
      {
        name: 'wellAPINumber',
        label: t('project.register.reservesSubmission.wellAPINumber'),
        required: true,
        inputProps: { ['data-cy']: 'wellAPINumber' },
        placeholder: '0',
        helperText: '14 digit API Number',
      },
      {
        name: 'holeLatitude',
        label: t('project.register.reservesSubmission.holeLatitude'),
        required: true,
        inputProps: { ['data-cy']: 'holeLatitude' },
        placeholder: '0.00000',
        helperText: 'WGS84 Surface Hole Latitude to at least 5 decimal places',
      },
      {
        name: 'wellType',
        label: t('project.register.reservesSubmission.wellType'),
        select: true,
        options: WELL_TYPE_OPTIONS,
        required: true,
        inputProps: { ['data-cy']: 'wellType' },
        helperText: 'Type of Role of Well in Project',
      },
      {
        name: 'holeLongitude',
        label: t('project.register.reservesSubmission.holeLongitude'),
        required: true,
        inputProps: { ['data-cy']: 'holeLongitude' },
        placeholder: '0.00000',
        helperText: 'WGS84 Surface Hole Longitude to at least 5 decimal places',
      },
    ],
    buttonText: t('general.buttons.save'),
    customStep: true,
  };
};
