import { TMenuSection } from '@energyweb/origin-ui-core';
import { TFunction } from 'i18next';

export type TGetVerifierMenuArgs = {
  t: TFunction;
  isOpen: boolean;
  showVerifierMenu: boolean;
  menuButtonClass?: string;
  selectedMenuItemClass?: string;
};

type TGetVerifierMenuFn = (args?: TGetVerifierMenuArgs) => TMenuSection;

export const getVerifierMenu: TGetVerifierMenuFn = ({
  t,
  isOpen,
  showVerifierMenu,
  selectedMenuItemClass,
  menuButtonClass,
}) => ({
  isOpen,
  dataCy: 'verifierMenu',
  sectionTitle: t('user.projectVerifier.menuItem'),
  rootUrl: '/verifier',
  show: showVerifierMenu,
  menuList: [
    {
      url: 'register',
      label: t('user.projectVerifier.menuItem'),
      show: showVerifierMenu,
      dataCy: 'project-verifier',
    },
  ],
  menuButtonClass,
  selectedMenuItemClass,
});
