import React, { FC } from 'react';
import { useParams } from 'react-router';
import { Paper, CircularProgress } from '@mui/material';
import { MultiStepForm } from '@energyweb/origin-ui-core';
import { useRegisterPageEffects } from './RegisterPage.effects';
import { useStyles } from './RegisterPage.styles';
import {
  useProjectData,
  useOwnerLocationData,
} from '@energyweb/origin-ui-organization-data';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

export const RegisterPage: FC = () => {
  const { id } = useParams();
  const { isLoading, project } = useProjectData(id && +id);
  const { location, isLoading: isLocationLoading } = useOwnerLocationData();

  localStorage.removeItem('project-data');

  if ((id && isLoading) || isLocationLoading) return <CircularProgress />;

  return (
    <RegisterPageLoaded
      project={{ ...project, location: project.location || location }}
    />
  );
};

export const RegisterPageLoaded: FC<{ project: ProjectDTO }> = ({
  project,
}) => {
  const classes = useStyles();
  const { formData } = useRegisterPageEffects(project);

  return (
    <Paper classes={{ root: classes.paper }}>
      <MultiStepForm {...formData} project={project} />
    </Paper>
  );
};

export default RegisterPage;
