import {
  CreateProjectDTO,
  ProjectDTO,
  getProjectControllerGetQueryKey,
  useProjectControllerCreate,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export const useProjectRegisterHandler = (project?: ProjectDTO) => {
  const { t } = useTranslation();
  const { mutate } = useProjectControllerCreate();
  const queryClient = useQueryClient();

  const submitHandler = (values: CreateProjectDTO) => {
    mutate(
      { data: { ...values } },
      {
        onSuccess: (result) => {
          showNotification(
            t('project.register.notifications.registeredSuccess'),
            NotificationTypeEnum.Success
          );
          const projectQueryKey = getProjectControllerGetQueryKey(result.id);
          queryClient.invalidateQueries(projectQueryKey);
          localStorage.setItem('project-data', JSON.stringify([result]));
        },
        onError: (error: any) => {
          if (error?.response?.status === 400) {
            showNotification(
              error?.response?.data?.message,
              NotificationTypeEnum.Error
            );
          } else {
            showNotification(
              `${t('project.register.notifications.registeredFailure')}:
              ${error?.response?.data?.message || ''}
              `,
              NotificationTypeEnum.Error
            );
          }
        },
      }
    );
  };

  return { submitHandler };
};
