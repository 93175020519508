import { TableCell, TableRow, Button, TextField } from '@mui/material';
import React, { PropsWithChildren, ReactElement } from 'react';
import { TableRowData } from '../../../containers';
import { useEditableTableComponentRowEffects } from './EditableTable.effects';
import { useStyles } from './EditableTable.styles';

export interface TableComponentRowProps<Id> {
  key: string;
  row: TableRowData<Id>;
  className?: string;
}

export type TTableComponentRow = <Id>(
  props: PropsWithChildren<TableComponentRowProps<Id>>
) => ReactElement;

export type TEditableTableComponentRow = <Id>(
  row: TableRowData<Id>
) => ReactElement;

const EditableTableCell: TEditableTableComponentRow = ({
  row,
  name,
  onChange,
}) => {
  const { isEditMode } = row;
  const classes = useStyles();

  return (
    <TableCell>
      {isEditMode ? (
        <TextField
          size="small"
          name={name}
          variant="standard"
          value={row[name]}
          onChange={(e) => onChange(e, row)}
          className={classes.noBorder}
        />
      ) : (
        row[name]
      )}
    </TableCell>
  );
};

export const EditableTableComponentRow: TTableComponentRow = ({
  row,
  className,
}) => {
  const { onToggleEditMode, onChange, buttonText, currentRow } =
    useEditableTableComponentRowEffects(row);

  return (
    <>
      <TableRow>
        {Object.keys(row).map(
          (key) =>
            (key === 'actions' &&
              row.actions?.map((action) => (
                <TableCell>
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={() => onToggleEditMode(currentRow.id, action)}
                  >
                    {buttonText}
                  </Button>
                </TableCell>
              ))) ||
            (key == 'id' && <TableCell>{currentRow.id}</TableCell>) || (
              <EditableTableCell
                {...{ row: currentRow, id: currentRow.id, name: key, onChange }}
              />
            )
        )}
      </TableRow>
    </>
  );
};
