import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { useRegisterCarbonContentHandler } from './carbonCredit';
import { useUpdateCarbonContentHandler } from './updateCarbonContent';
import { useProjectRegisterHandler } from './projectRegistration';
import { useProjectUpdateHandler } from './updateProjectHandler';
import { useReservesSubmissionHandler } from './reservesSubmission';
import { useUpdateReservesSubmissionHandler } from './updateReservesSubmission';
import { useUpdateReservesValidationHandler } from './updateReservesValidation';
import { useReservesValidationHandler } from './reservesValidation';
import { useOwnershipHandler } from './ownership';
import { useOwnershipUpdateHandler } from './updateOwnership';

export const useProjectRegisterStepsHandler = (
  activeStep: number,
  project?: ProjectDTO
) => {
  switch (activeStep) {
    case 0: {
      if (project?.id) {
        return useProjectUpdateHandler(project?.id);
      }
      return useProjectRegisterHandler();
    }
    case 1: {
      if (project?.carbonContent?.id) {
        return useUpdateCarbonContentHandler(
          project?.carbonContent?.id,
          project
        );
      }
      return useRegisterCarbonContentHandler(project);
    }
    case 2: {
      if (project?.reservesSubmission?.id) {
        return useUpdateReservesSubmissionHandler(
          project?.reservesSubmission?.id,
          project
        );
      }
      return useReservesSubmissionHandler(project);
    }
    case 3: {
      if (project?.reservesValidation?.id) {
        return useUpdateReservesValidationHandler(
          project?.reservesValidation?.id,
          project
        );
      }
      return useReservesValidationHandler(project);
    }
    case 4: {
      if (project?.ownership?.id) {
        return useOwnershipUpdateHandler(project?.ownership?.id, project);
      }
      return useOwnershipHandler(project);
    }
    default: {
      return useProjectRegisterHandler();
    }
  }
};
