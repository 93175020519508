import * as yup from 'yup';
import React from 'react';
import { OwnershipForm } from '@energyweb/origin-ui-organization-view';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { TOwnershipForm } from './types';

const OwnershipFormWrapper = (project: ProjectDTO) => (props: any) =>
  <OwnershipForm {...props} project={project} />;

export const ownershipForm: TOwnershipForm = (t, project?: ProjectDTO) => {
  return {
    formTitle: t('project.register.ownershipTitle'),
    inputsVariant: 'outlined',
    initialValues: {
      riskTypeId: project?.ownership?.riskOption?.type?.id,
      riskOptionId: project?.ownership?.riskOption?.id,
      uploads: [],
      projectId: project?.id,
    },
    validationSchema: yup.object().shape({
      riskOptionId: yup
        .number()
        .required()
        .label(t('project.register.ownership.riskOptionId')),
      riskTypeId: yup
        .number()
        .required()
        .label(t('project.register.ownership.riskTypeId')),
    }),
    fields: [
      {
        name: 'riskTypeId',
        label: t('project.register.ownership.riskTypeId'),
        required: true,
        select: true,
        options: [],
        inputProps: { ['data-cy']: 'riskTypeId' },
        placeholder: 'Select your Type',
        helperText: 'Types of Mineral Owner',
      },
      {
        name: 'riskOptionId',
        label: t('project.register.ownership.riskOptionId'),
        required: true,
        select: true,
        options: [],
        inputProps: { ['data-cy']: 'riskOptionId' },
        placeholder: 'Choose from options based on Type of Mineral Owner',
        helperText: 'Definition of Risk Owner',
      },
      {
        name: 'uploads',
        file: true,
        label: t('project.register.ownership.uploadMineralRight'),
        required: true,
        inputProps: { ['data-cy']: 'uploads' },
      },
    ],
    buttonText: t('general.buttons.save'),
    component: OwnershipFormWrapper(project),
    customStep: true,
  };
};
