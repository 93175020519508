import {
  CreateCarbonContentDTO,
  useCarbonContentControllerUpdate,
  ProjectDTO,
  getProjectControllerGetQueryKey,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export const useUpdateCarbonContentHandler = (
  id: number,
  project?: ProjectDTO
) => {
  const { t } = useTranslation();
  const { mutate } = useCarbonContentControllerUpdate();

  const storedData = JSON.parse(localStorage.getItem('project-data')) || [
    project,
  ];
  const projectId = storedData[0]?.id;

  const queryClient = useQueryClient();
  const projectQueryKey = getProjectControllerGetQueryKey(projectId);

  const submitHandler = (values: CreateCarbonContentDTO) => {
    mutate(
      { id, data: { ...values } },
      {
        onSuccess: (result) => {
          showNotification(
            t('project.register.notifications.carbonContentUpdateSuccess'),
            NotificationTypeEnum.Success
          );
          queryClient.invalidateQueries(projectQueryKey);
        },
        onError: (error: any) => {
          if (error?.response?.status === 400) {
            showNotification(
              error?.response?.data?.message,
              NotificationTypeEnum.Error
            );
          } else {
            showNotification(
              `${t(
                'project.register.notifications.carbonContentUpdateFailure'
              )}:
                  ${error?.response?.data?.message || ''}
                  `,
              NotificationTypeEnum.Error
            );
          }
        },
      }
    );
  };
  return { submitHandler };
};
