import React, { FC } from 'react';
import {
  ButtonsGroup,
  FileUpload,
  GenericFormSecondaryButton,
} from '@energyweb/origin-ui-core';
import { ReservesSubmissionFormValues } from '@energyweb/origin-ui-organization-logic';
import { Box, Grid } from '@mui/material';
import { useStyles } from './ReservesSubmission.styles';
import { UnpackNestedValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useReservesSubmissionEffects } from './ReservesSubmission.effects';
import { WellsPage } from '../WellsViewPage';
import { ProjectWellForm } from '../ProjectWells/ProjectWellRegistrationPage';
import {
  fileControllerUploadToIPFS,
  ProjectDTO,
  reservesSubmissionControllerCalculateGasPredictedOutput,
  reservesSubmissionControllerCalculateOilPredictedOutput,
} from '@energyweb/origin-backend-react-query-client';
import { Upload } from '@energyweb/origin-backend-core';

export interface ReservesSubmissionProps {
  submitHandler?: (
    values: UnpackNestedValue<ReservesSubmissionFormValues>
  ) => Promise<void>;
  secondaryButtons?: GenericFormSecondaryButton[];
  loading?: boolean;
  project?: ProjectDTO;
}

export const ReservesSubmissionForm: FC<ReservesSubmissionProps> = ({
  submitHandler,
  secondaryButtons,
  loading,
  project,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    showProjectWells,
    documents,
    handleSubmit,
    uploadFunction,
    onChange,
  } = useReservesSubmissionEffects(project);

  const onSubmit = async () => {
    const uploads = project?.reservesSubmission?.uploads || [];
    const values: ReservesSubmissionFormValues = {
      oilPredictedOutput: 0,
      gasPredictedOutput: 0,
      uploads,
      projectId: project?.id,
    };

    const files = documents.filter(
      (uploadedFile: File) =>
        !values.uploads.some((file: Upload) => file.name === uploadedFile.name)
    );
    if (files.length > 0) {
      const uploadedFiles: Upload[] = await fileControllerUploadToIPFS({
        files,
      });
      values.uploads = [...values.uploads, ...uploadedFiles];
    }

    // todo: check file name
    const oilPredictedOutputRequest =
      reservesSubmissionControllerCalculateOilPredictedOutput(
        project?.id,
        values.uploads[0]
      );
    const gasPredictedOutputRequest =
      reservesSubmissionControllerCalculateGasPredictedOutput(
        project?.id,
        values.uploads[0]
      );
    Promise.all([oilPredictedOutputRequest, gasPredictedOutputRequest]).then(
      (predictedOutputs) => {
        ({ 0: values.oilPredictedOutput, 1: values.gasPredictedOutput } =
          predictedOutputs);
        submitHandler(values);
      }
    );
  };

  return (
    <Box className={classes.form}>
      {showProjectWells && <WellsPage project={project} />}
      <ProjectWellForm project={project} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={12} mt={5}>
            <FileUpload
              heading={t('project.register.reservesSubmission.uploadForecast')}
              dropzoneText={t('file.upload.dropOrClick')}
              apiUploadFunction={uploadFunction}
              onChange={onChange}
              wrapperProps={{ ['data-cy']: 'monthlyForecast' }}
            />
            <FileUpload
              heading={t('project.register.reservesSubmission.uploadReserves')}
              dropzoneText={t('file.upload.dropOrClick')}
              apiUploadFunction={uploadFunction}
              onChange={onChange}
              wrapperProps={{ ['data-cy']: 'wellLevelReserves' }}
            />
          </Grid>
          <ButtonsGroup secondaryButtons={secondaryButtons} loading={loading} />
        </Grid>
      </form>
    </Box>
  );
};
