import {
  useProjectOwnerControllerRegister,
  RegisterProjectOwnerDTO,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export type TCreateProjectOwnerFormValues = {
  state: RegisterProjectOwnerDTO['state'];
  license: RegisterProjectOwnerDTO['license'];
  status: RegisterProjectOwnerDTO['status'];
};

export const useApiCreateProjectOwnerData = () => {
  const { t } = useTranslation();

  const { mutate } = useProjectOwnerControllerRegister();
  const navigate = useNavigate();

  const submitHandler = (values: TCreateProjectOwnerFormValues) => {
    mutate(
      { data: { ...values } },
      {
        onSuccess: () => {
          showNotification(
            t('user.projectOwner.notifications.registeredSuccess'),
            NotificationTypeEnum.Success
          );
          navigate('/organization/register');
        },
        onError: (error: any) => {
          showNotification(
            `${t('user.projectOwner.notifications.registeredFailure')}:
                ${error?.response?.data?.message || ''}
                `,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };

  return {
    submitHandler,
  };
};
