import { makeStyles } from '@mui/styles';

const statusModel = () => ({
  textTransform: 'uppercase',
  '&:before': {
    content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%23111111'}'/></svg>")`,
    verticalAlign: 'middle',
    fill: 'black',
    marginRight: 7,
  },
});

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  header: {
    color: '#2B201F',
    fontSize: 24,
    marginBottom: 25,
    marginLeft: 30,
  },

  paper: {
    background: '#FEFFFF',
    boxShadow: '0px 6px 24px rgba(25, 29, 56, 0.16)',
    padding: 36,

    '&& .MuiTable-root': {
      borderBottom: 'none',
    },

    '&& .MuiTableHead-root': {
      background: '#0FB8C317',
      fontFamily: theme.typography.fontFamily,
      fontSize: 14,
      color: '#2B201F',
      borderBottom: 'none',
    },
    '&& .MuiTableBody-root tr': {
      backgroundColor: '#fff',
      borderBottom: '1px solid #E4E7EB',
      fontFamily: theme.typography.fontFamily,
      fontSize: 13,
      '&:hover': {
        boxShadow: '0px 7.83465px 38.3898px rgba(0, 0, 0, 0.13)',
      },
    },
    '&& .MuiTableCell-root': {
      color: '#5E5E5E',
    },
    '&& .MuiTableRow-footer': {
      display: 'none',
    },
  },
  status: {
    textTransform: 'uppercase',
    '&:before': {
      verticalAlign: 'middle',
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%23FFDA92'}'/></svg>")`,
      fill: 'black',
      marginRight: 7,
    },
    '&.Pending:before': {
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%23D1FFF7'}'/></svg>")`,
    },
    '&.Verified:bedore': {
      content: `url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='9' width='9'><path fill-rule='evenodd' clip-rule='evenodd' d='M4.2601 8.34327C6.61275 8.34327 8.51995 6.51985 8.51995 4.27054C8.51995 2.02124 6.61275 0.197815 4.2601 0.197815C1.90745 0.197815 0.000244141 2.02124 0.000244141 4.27054C0.000244141 6.51985 1.90745 8.34327 4.2601 8.34327Z' fill='${'%23FF7171'}'/></svg>")`,
    },
  },
}));
