import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Edit } from '@mui/icons-material';
import { TableActionData } from '@energyweb/origin-ui-core';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

import { useProjectList } from '@energyweb/origin-ui-organization-data';

export const useProjectListingEffects = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { projects } = useProjectList();

  const actions: TableActionData<ProjectDTO['id']>[] = [
    {
      icon: <Edit data-cy="editIcon" />,
      name: t('project.listing.actions.edit'),
      onClick: (id) => {
        navigate(`/organization/project/${id}/edit`);
      },
    },
  ];

  const header = {
    id: t('project.listing.id'),
    name: t('project.listing.name'),
    location: t('project.listing.location'),
    projectStatus: t('project.listing.status'),
    actions: '',
  };

  return {
    projects,
    header,
    actions,
  };
};
