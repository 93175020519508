import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Typography } from '@mui/material';
import { TableComponent } from '@energyweb/origin-ui-core';
import { useStyles } from './ProjectsListingPage.styles';
import { useProjectListingEffects } from './ProjectsListingPage.effects';

export const ProjectsListingPage: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { projects, header, actions } = useProjectListingEffects();

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        {t('project.listing.title')}
      </Typography>
      <Paper classes={{ root: classes.paper }}>
        <TableComponent
          header={header}
          data={
            projects
              ? projects.map((project, i) => ({
                  id: project.id || i,
                  name: project.name,
                  location: project.location,
                  projectStatus: (
                    <div
                      className={classes.status + ' ' + project.projectStatus}
                    >
                      {project.projectStatus}
                    </div>
                  ),
                  actions,
                }))
              : []
          }
        />
      </Paper>
    </div>
  );
};

export default ProjectsListingPage;
