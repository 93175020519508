import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },

  paper: {
    boxShadow: 'none',
    background: '#FFF',
    '&& .MuiTable-root': {
      borderBottom: 'none',
    },

    '&& .MuiTableBody-root tr, th': {
      backgroundColor: '#fff',
      borderBottom: '1px solid #AAB0F3',
      fontFamily: theme.typography.fontFamily,
    },
    '&& .MuiTableCell-root': {
      color: '#5E5E5E',
    },
    '&& .MuiTableRow-footer': {
      display: 'none',
    },
  },
  warningText: {
    fontFamily: 'Gotham',
    fontSize: '10px',
    color: '#AAB0F3',
    margin: '10px 0',
  },
}));
