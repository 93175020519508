import {
  ButtonsGroup,
  FormInput,
  FormSelect,
  GenericFormSecondaryButton,
} from '@energyweb/origin-ui-core';
import { ProjectInfoFormValues } from '@energyweb/origin-ui-organization-logic';
import { Grid, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useStyles } from './ProjectInformation.styles';
import { UnpackNestedValue } from 'react-hook-form';
import { useCreateProjectEffects } from './ProjectInformation.effects';
import { isEmpty } from 'lodash';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

export interface CreateProjectProps {
  submitHandler?: (
    values: UnpackNestedValue<ProjectInfoFormValues>
  ) => Promise<void>;
  secondaryButtons?: GenericFormSecondaryButton[];
  loading?: boolean;
  project?: ProjectDTO;
}

export const ProjectRegistrationForm: FC<CreateProjectProps> = ({
  submitHandler,
  secondaryButtons,
  loading,
  project,
}) => {
  const classes = useStyles();

  const { errors, fields, dirtyFields, handleSubmit, register, control } =
    useCreateProjectEffects(project);

  const onSubmit = (value: any) => {
    return submitHandler(value);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Grid container>
        <Grid item xs={12} sm={12}>
          {fields.map(
            (field, index) =>
              (field.select && (
                <Grid my={1} key={index}>
                  <Typography variant="subtitle1">{field.label}</Typography>
                  <FormSelect
                    key={field.label}
                    field={field}
                    control={control}
                    errorExists={!isEmpty((errors as any)[field.name])}
                    errorText={(errors as any)[field.name]?.message ?? ''}
                    variant={'outlined'}
                    disabled={false}
                    register={register}
                  />
                </Grid>
              )) || (
                <Grid my={1} key={index}>
                  <Typography variant="subtitle1">{field.label}</Typography>
                  <FormInput
                    key={field.label}
                    field={field}
                    disabled={false}
                    register={register}
                    errorExists={!isEmpty((errors as any)[field.name])}
                    errorText={(errors as any)[field.name]?.message ?? ''}
                    isDirty={!!(dirtyFields as any)[field.name]}
                    variant={'outlined'}
                    {...field.inputProps}
                  />
                </Grid>
              )
          )}
        </Grid>
        <ButtonsGroup
          backButtonExists={false}
          secondaryButtons={secondaryButtons}
          loading={loading}
        />
      </Grid>
    </form>
  );
};
