import {
  ProjectDTO,
  projectWellControllerUpdate,
  ProjectWellDTO,
} from '@energyweb/origin-backend-react-query-client';
import { TableActionData } from '@energyweb/origin-ui-core';
import { Edit } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const useWellsListingEffects = (project: ProjectDTO) => {
  const { t } = useTranslation();
  const wells = project?.reservesSubmission?.wells || [];

  const formatWells = () => {
    return wells?.map((well) => ({
      id: well.id,
      wellName: well.wellName,
      wellAPINumber: well.wellAPINumber,
      wellType: well.wellType,
      holeLatitude: well.holeLatitude,
      holeLongitude: well.holeLongitude,
      actions,
    }));
  };

  const actions: TableActionData<ProjectWellDTO['id']>[] = [
    {
      icon: <Edit data-cy="editIcon" />,
      name: t('general.buttons.edit'),
      onClick: async (id, data) => {
        data.reservesSubmissionId = project?.reservesSubmission?.id;
        await projectWellControllerUpdate(id, data);
      },
    },
  ];

  const header = {
    id: '#',
    wellName: t('project.register.reservesSubmission.wellName'),
    wellAPINumber: t('project.register.reservesSubmission.wellAPINumber'),
    wellType: t('project.register.reservesSubmission.wellType'),
    holeLatitude: t('project.register.reservesSubmission.holeLatitude'),
    holeLongitude: t('project.register.reservesSubmission.holeLongitude'),
    actions: 'Actions',
  };
  const tableData = {
    header,
    data: formatWells(),
    editable: true,
  };
  return {
    tableData,
  };
};
