import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

import { createProjectInfoForm } from 'libs/organization/logic/src/register/projectInformationForm';

export const useCreateProjectEffects = (project?: ProjectDTO) => {
  const { t } = useTranslation();

  const { initialValues, validationSchema, fields, validationMode } =
    createProjectInfoForm(t, project);

  const { register, handleSubmit, formState, control } = useForm({
    mode: validationMode,
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const { isValid, errors, dirtyFields } = formState;

  return {
    errors,
    control,
    isValid,
    fields,
    initialValues,
    dirtyFields,
    handleSubmit,
    register,
  };
};
