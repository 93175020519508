import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  paper: {
    boxShadow: 'none',
    background: '#FFF',
    '&& .MuiTableRow-footer': {
      display: 'none',
    },
  },
}));
