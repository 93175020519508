import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { UploadedFile } from '@energyweb/origin-ui-core';
import { reservesValidationForm } from 'libs/organization/logic/src/register/reservesValidationForm';
import {
  prepareCompanyMembersOptions,
  prepareCompanyMemberEmail,
} from '@energyweb/origin-ui-utils';
import { useVerifierCompanies } from '@energyweb/origin-ui-user-data';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

export const useReservesValidationEffects = (project: ProjectDTO) => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<File[]>([]);
  const { verifierCompanies } = useVerifierCompanies();

  const { initialValues, validationSchema, fields, validationMode } =
    reservesValidationForm(t, verifierCompanies, project);

  const [_fields, setFields] = useState<any>(fields);

  const { register, handleSubmit, formState, control, watch, setValue } =
    useForm({
      mode: 'onChange',
      resolver: yupResolver(validationSchema),
      defaultValues: initialValues,
    });

  const { errors } = formState;
  const { companyId, verifierId } = watch();

  const emailIndex = fields.findIndex((field) => field.name === 'email');
  const verifierIndex = fields.findIndex(
    (field) => field.name === 'verifierId'
  );
  const companyIndex = fields.findIndex((field) => field.name === 'companyId');

  useEffect(() => {
    fields[companyIndex].options = verifierCompanies?.map((company) => ({
      label: company.name,
      value: company.id,
    }));
    setFields([...fields]);
  }, [verifierCompanies]);

  useEffect(() => {
    setValue('email', '');
    fields[emailIndex].textFieldProps.value = '';
    fields[verifierIndex].options = prepareCompanyMembersOptions(
      verifierCompanies,
      companyId
    );
    setFields([...fields]);
  }, [companyId, verifierId]);

  useEffect(() => {
    fields[emailIndex].textFieldProps.value = prepareCompanyMemberEmail(
      verifierCompanies,
      verifierId
    );
    setFields([...fields]);
  }, [verifierId]);

  const uploadFunction = async (file: File[]) => {
    const fileIndex = documents.findIndex(
      (existingFile: File) => existingFile.name == file[0]?.name
    );
    if (fileIndex === -1) {
      setDocuments((prev: File[]) => [...prev, ...file]);
    }
    return documents;
  };

  const onChange = (file: UploadedFile[]) => {
    if (file[0]?.removed) {
      const files = documents.filter(
        (document: File) => document.name !== file[0].uploadedName
      );
      setDocuments(files);
    }
  };

  return {
    initialValues,
    validationSchema,
    fields: _fields,
    validationMode,
    documents,
    control,
    errors,
    register,
    handleSubmit,
    uploadFunction,
    onChange,
  };
};
