import { useTranslation } from 'react-i18next';
import { UserAccountDTO } from '@energyweb/origin-backend-react-query-client';
import {
  TableActionData,
  TableRowData,
  TableComponentProps,
} from '@energyweb/origin-ui-core';
import { TFunction } from 'react-i18next';

export type UserAccountsTableArgs = {
  accounts: UserAccountDTO[];
  actions: TableActionData<UserAccountDTO['id']>[];
  loading: boolean;
};

export type UserAccountTableFormat = (
  props: Omit<UserAccountsTableArgs, 'loading'> & {
    t: TFunction<'translation'>;
  }
) => TableRowData<UserAccountDTO['id']>[];

export type UserAccountsTableLogic = (
  props: UserAccountsTableArgs
) => TableComponentProps<UserAccountDTO['id']>;

export const formatTable: UserAccountTableFormat = ({
  t,
  accounts,
  actions,
}) => {
  return accounts?.map((account) => ({
    id: account.id,
    name: account.name,
    IBAN: account.IBAN,
    creditCard: account.creditCard,
    debitCard: account.debitCard,
    actions,
  }));
};

export const useAccountsTableLogic: UserAccountsTableLogic = ({
  accounts,
  actions,
  loading,
}) => {
  const { t } = useTranslation();
  return {
    header: {
      name: t('user.account.name'),
      IBAN: t('user.account.iban'),
      creditCard: t('user.account.credit'),
      debitCard: t('user.account.debit'),
      actions: '',
    },
    loading: loading,
    data: formatTable({ t, accounts, actions }) ?? [],
  };
};
