import React, { FC } from 'react';
import { FormInput } from '@energyweb/origin-ui-core';
import { Paper, Button, Grid, Typography } from '@mui/material';
import { useStyles } from './RoyaltyOwnerRegistration.styles';
import { useRoyaltyOwnerEffects } from './RoyaltyOwnerRegistration.effects';
import { isEmpty } from 'lodash';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { RoyaltyOwnersListingPage } from '../RoyaltyOwnersListingPage';
import { t } from 'i18next';

export interface RoyaltyOwnerProps {
  project?: ProjectDTO;
}

export const RegisterRoyaltyOwnerForm: FC<RoyaltyOwnerProps> = ({
  project,
}) => {
  const classes = useStyles();

  const {
    errors,
    dirtyFields,
    fields,
    formInputsProps,
    royaltyOwners,
    reset,
    handleSubmit,
    register,
    submitHandler,
  } = useRoyaltyOwnerEffects(project);

  const onSubmit = (value: any) => {
    submitHandler(value);
    reset();
  };
  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="subtitle1">
          {t('project.register.royaltyOwner.register')}
        </Typography>
        <form className={classes.form}>
          <Grid container spacing={2}>
            {fields.map((field, index) => (
              <Grid item xs={4} key={index}>
                <FormInput
                  field={field}
                  disabled={false}
                  register={register}
                  errorExists={!isEmpty((errors as any)[field.name])}
                  errorText={(errors as any)[field.name]?.message ?? ''}
                  isDirty={!!(dirtyFields as any)[field.name]}
                  variant={'outlined'}
                  {...formInputsProps}
                />
              </Grid>
            ))}
            <Grid item>
              <Button
                color="primary"
                name="submit"
                size="large"
                variant="outlined"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                {t('project.register.royaltyOwner.submit')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      {royaltyOwners.length > 0 && (
        <RoyaltyOwnersListingPage project={project} />
      )}
    </>
  );
};
