import React, { FC } from 'react';
import { Paper } from '@mui/material';
import { TableComponent } from '@energyweb/origin-ui-core';
import { useWellsListingEffects } from './WellsViewPage.effects';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { useStyles } from './WellsViewPage.styles';

export interface WellListingProps {
  project?: ProjectDTO;
}

export const WellsPage: FC<WellListingProps> = ({ project }) => {
  const { tableData } = useWellsListingEffects(project);
  const classes = useStyles();

  return (
    <Paper classes={{ root: classes.paper }}>
      <TableComponent {...tableData} />
    </Paper>
  );
};

export default WellsPage;
