import { registerCarbonForm } from 'libs/organization/logic/src/register/carbonContentForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { UploadedFile } from '@energyweb/origin-ui-core';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

export const useCarbonContentEffects = (project?: ProjectDTO) => {
  const { t } = useTranslation();

  const [documents, setDocuments] = useState<File[]>([]);

  const { initialValues, validationSchema, fields, validationMode } =
    registerCarbonForm(t, project);

  const { register, handleSubmit, formState, control } = useForm({
    mode: validationMode,
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const { isValid, errors, dirtyFields } = formState;

  const selectField = fields.find((field) => field.name === 'type');
  const firstColumn = fields.filter(
    (field) =>
      field.name === 'gasHeatingValue' || field.name === 'oilApiGravity'
  );
  const secondColumn = fields.slice(3, fields.length - 1);
  const gasShrinkageField = fields.find(
    (field) => field.name === 'gasShrinkage'
  );

  const uploadFunction = async (file: File[]) => {
    const fileIndex = documents.findIndex(
      (existingFile: File) => existingFile.name == file[0].name
    );
    if (fileIndex === -1) {
      setDocuments((prev: File[]) => [...prev, ...file]);
    }
    return documents;
  };

  const onChange = (file: UploadedFile[]) => {
    if (file[0]?.removed) {
      const files = documents.filter(
        (document: File) => document.name !== file[0].uploadedName
      );
      setDocuments(files);
    }
  };

  return {
    errors,
    isValid,
    fields,
    initialValues,
    selectField,
    firstColumn,
    secondColumn,
    gasShrinkageField,
    dirtyFields,
    control,
    documents,
    uploadFunction,
    onChange,
    handleSubmit,
    register,
  };
};
