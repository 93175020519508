import React, { FC } from 'react';
import { UnpackNestedValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import {
  ButtonsGroup,
  FileUpload,
  FormSelect,
  GenericFormSecondaryButton,
} from '@energyweb/origin-ui-core';
import { OwnershipFormValues } from '@energyweb/origin-ui-organization-logic';
import {
  fileControllerUploadToIPFS,
  ProjectDTO,
} from '@energyweb/origin-backend-react-query-client';
import { Upload } from '@energyweb/origin-backend-core';

import { useStyles } from './Ownership.styles';
import { useOwnershipEffects } from './Ownership.effects';
import { isEmpty } from 'lodash';
import { RegisterRoyaltyOwnerForm } from '../RoyaltyOwnerRegistrationForm/RoyaltyOwnerRegistration';

export interface OwnershipProps {
  submitHandler?: (
    values: UnpackNestedValue<OwnershipFormValues>
  ) => Promise<void>;
  secondaryButtons?: GenericFormSecondaryButton[];
  loading?: boolean;
  project?: ProjectDTO;
}

export const OwnershipForm: FC<OwnershipProps> = ({
  submitHandler,
  secondaryButtons,
  loading,
  project,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    documents,
    fields,
    control,
    errors,
    register,
    handleSubmit,
    uploadFunction,
    onChange,
  } = useOwnershipEffects(project);

  const onSubmit = async (values: OwnershipFormValues) => {
    const files = documents.filter(
      (uploadedFile: File) =>
        !values.uploads.some((file: Upload) => file.name === uploadedFile.name)
    );
    if (files.length > 0) {
      const uploadedFiles: Upload[] = await fileControllerUploadToIPFS({
        files,
      });
      values.uploads = [...values.uploads, ...uploadedFiles];
    }
    submitHandler(values);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        {fields.map(
          (field: any, index: number) =>
            (field.select && (
              <Grid my={1} key={index}>
                <Typography variant="subtitle1">{field.label}</Typography>
                <FormSelect
                  key={field.label}
                  field={field}
                  control={control}
                  errorExists={!isEmpty((errors as any)[field.name])}
                  errorText={(errors as any)[field.name]?.message ?? ''}
                  variant={'outlined'}
                  disabled={false}
                  register={register}
                />
              </Grid>
            )) ||
            (field.file && (
              <FileUpload
                key={index}
                heading={field.label}
                dropzoneText={t('file.upload.dropOrClick')}
                apiUploadFunction={uploadFunction}
                onChange={onChange}
                wrapperProps={{ ['data-cy']: 'uploads' }}
              />
            ))
        )}
        <RegisterRoyaltyOwnerForm project={project} />
        <ButtonsGroup secondaryButtons={secondaryButtons} loading={loading} />
      </form>
    </Box>
  );
};
