import { BoxProps, List, Drawer, PaperProps } from '@mui/material';
import React, { FC, ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useUser } from '@energyweb/origin-ui-user-data';
import { isRole, Role } from '@energyweb/origin-backend-core';

import {
  // UsernameAndOrg,
  UsernameAndOrgProps,
} from '../../layout/UsernameAndOrg';
import { TMenuSection, NavBarSection } from '../NavBarSection';
import { useStyles } from './DesktopNav.styles';

export interface DesktopNavProps {
  userAndOrgData: UsernameAndOrgProps;
  isAuthenticated: boolean;
  menuSections: TMenuSection[];
  icon?: ReactNode;
  iconWrapperProps?: BoxProps;
  sidebarPaperProps?: PaperProps;
}

export const DesktopNav: FC<DesktopNavProps> = ({
  userAndOrgData,
  menuSections = [],
  isAuthenticated,
  sidebarPaperProps,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const { user } = useUser();
  const location = useLocation();

  const userIsProjectOwner = isRole(user, Role.ProjectOwner);
  const exceptions = ['/organization/register'];
  const additionalClass = open ? '' : ' closed';

  const getProps =
    exceptions.includes(location.pathname) && userIsProjectOwner
      ? {
          onMouseEnter: () => setOpen(true),
          onMouseLeave: () => setOpen(false),
        }
      : {};

  return (
    <Drawer
      open
      anchor="left"
      variant="permanent"
      hideBackdrop
      className={classes.drawer + additionalClass}
      PaperProps={sidebarPaperProps}
      {...getProps}
    >
      <List className={classes.list + additionalClass}>
        {menuSections.map((section) => (
          <NavBarSection key={section.sectionTitle} {...section} />
        ))}
      </List>
    </Drawer>
  );
};
