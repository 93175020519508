import * as yup from 'yup';
import { TReservesValidationForm } from './types';
import { ReservesValidationForm } from '@energyweb/origin-ui-organization-view';
import {
  useVerifierCompaniesOptions,
  prepareCompanyMembersOptions,
} from '@energyweb/origin-ui-utils';
import {
  ProjectDTO,
  VRCompany,
} from '@energyweb/origin-backend-react-query-client';
import React from 'react';

const ReservesValidationFormWrapper = (project: ProjectDTO) => (props: any) =>
  <ReservesValidationForm {...props} project={project} />;

export const reservesValidationForm: TReservesValidationForm = (
  t,
  verifierCompanies?: VRCompany[],
  project?: ProjectDTO
) => {
  const { VERIFIER_COMPANY_OPTIONS } =
    useVerifierCompaniesOptions(verifierCompanies);
  const reservesValidation = project?.reservesValidation;

  return {
    formTitle: t('project.register.validation'),
    inputsVariant: 'outlined',
    initialValues: {
      companyId: reservesValidation?.verifier?.company?.id || null,
      verifierId: reservesValidation?.verifier?.id || null,
      email: reservesValidation?.verifier?.email || '',
      uploads: [],
      projectId: project?.id,
    },
    validationSchema: yup.object().shape({
      companyId: yup
        .number()
        .required()
        .label(t('project.register.reservesValidation.companyId')),
      verifierId: yup
        .number()
        .required()
        .label(t('project.register.reservesValidation.verifierId')),
    }),
    fields: [
      {
        name: 'companyId',
        label: t('project.register.reservesValidation.companyId'),
        required: true,
        select: true,
        options: VERIFIER_COMPANY_OPTIONS,
        inputProps: { ['data-cy']: 'companyId' },
        placeholder: 'Third Party Reserves Provider',
        helperText: 'Verifier companies',
      },
      {
        name: 'verifierId',
        label: t('project.register.reservesValidation.verifierId'),
        required: true,
        select: true,
        options: prepareCompanyMembersOptions(
          verifierCompanies,
          reservesValidation?.verifier?.company?.id
        ),
        inputProps: { ['data-cy']: 'verifierId' },
        placeholder: 'Accredited Engineer',
        helperText: 'Choose specific VR from a company provider',
      },
      {
        name: 'email',
        label: t('project.register.reservesValidation.email'),
        required: false,
        textFieldProps: { value: reservesValidation?.verifier?.email || '' },
        inputProps: { ['data-cy']: 'email' },
        placeholder: 'Accredited Engineer Email',
        helperText: 'Email address',
      },
      {
        name: 'uploads',
        file: true,
        label: t('project.register.reservesValidation.uploadReservesReport'),
        required: true,
        inputProps: { ['data-cy']: 'uploads' },
      },
    ],
    buttonText: t('general.buttons.save'),
    component: ReservesValidationFormWrapper(project),
    customStep: true,
  };
};
