import { useProjectRegisterStepsHandler } from '@energyweb/origin-ui-organization-data';
import React, { useState, useCallback } from 'react';
import { UnpackNestedValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GenericForm } from '../GenericForm';
import { MultiStepFormProps } from './MultiStepForm';
import _ from 'lodash';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

type TUseMultiStepEffectsArgs<Merged> = Pick<
  MultiStepFormProps<Merged>,
  | 'forms'
  | 'backButtonText'
  | 'backButtonProps'
  | 'loading'
  | 'nextButtonText'
  | 'project'
>;

type TUseMultiStepEffects = <Merged>(
  args: TUseMultiStepEffectsArgs<Merged>
) => {
  stepperLabels: string[];
  activeStep: number;
  prevStep: number;
  getCurrentForm: (step: number) => JSX.Element;
};

const PROJECT_STEPS_MAP = [
  'id',
  'carbonContent.id',
  'reservesSubmission.id',
  'reservesValidation.id',
  'ownership.id',
];

const getActiveStep = (project?: ProjectDTO) => {
  const currentStep = PROJECT_STEPS_MAP.findIndex((e) => !_.get(project, e));
  return currentStep === -1 ? PROJECT_STEPS_MAP.length - 1 : currentStep;
};

export const useMultiStepFormEffects: TUseMultiStepEffects = ({
  forms,
  backButtonText,
  backButtonProps,
  nextButtonText,
  loading,
  project,
}) => {
  const [activeStep, setActiveStep] = useState(getActiveStep(project));
  const [prevStep, setPrevStep] = useState(activeStep ? activeStep - 1 : 0);
  const storedData = JSON.parse(localStorage.getItem('project-data')) || [
    project,
  ];

  const { submitHandler } = useProjectRegisterStepsHandler(activeStep, project);

  const { t } = useTranslation();
  const stepperLabels = [
    t('project.register.infoTitle'),
    t('project.register.carbonContentTitle'),
    t('project.register.submission'),
    t('project.register.validation'),
    t('project.register.ownershipTitle'),
    t('project.register.supplementalData'),
    t('project.register.preview'),
    t('project.register.overview'),
  ];

  const nextButtonHandler = useCallback((): void => {
    if (activeStep + 1 >= forms.length) {
      return;
    }
    setPrevStep(activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [forms, activeStep]);

  const saveButtonHandler = useCallback(
    async (values: UnpackNestedValue<any>): Promise<void> => {
      if (values) {
        return submitHandler(values);
      }
    },
    [activeStep, project]
  );

  const backButtonHandler = useCallback((): void => {
    if (activeStep === 0) {
      return;
    }
    setPrevStep(activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, [activeStep]);

  const getCurrentForm = (step: number) => {
    const props = forms[step];

    if (props?.customStep && props?.component) {
      const CustomForm = props.component;

      return (
        <CustomForm
          submitHandler={saveButtonHandler}
          secondaryButtons={[
            {
              label: backButtonText,
              onClick: backButtonHandler,
              disabled: activeStep === 0 || loading,
              variant: 'outlined',
              style: { marginRight: 10 },
              ...backButtonProps,
            },
            {
              label: nextButtonText,
              onClick: nextButtonHandler,
              disabled:
                !(
                  storedData[activeStep]?.id ||
                  _.get(project, PROJECT_STEPS_MAP[activeStep])
                ) || loading,
              variant: 'outlined',
              style: { marginLeft: 10 },
              ...backButtonProps,
            },
          ]}
          loading={loading}
          {...props}
        />
      );
    }

    return (
      <GenericForm
        partOfMultiForm={true}
        submitHandler={saveButtonHandler}
        secondaryButtons={[
          {
            label: backButtonText,
            onClick: backButtonHandler,
            disabled: activeStep === 0,
            variant: 'outlined',
            style: { marginRight: 10 },
            ...backButtonProps,
          },
          {
            label: nextButtonText,
            onClick: nextButtonHandler,
            variant: 'outlined',
            disabled: loading,
            style: { marginRight: 10 },
            ...backButtonProps,
          },
        ]}
        loading={loading}
        {...props}
      />
    );
  };

  return { stepperLabels, activeStep, getCurrentForm, prevStep };
};
