import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  stepper: {
    margin: '30px 0',
  },
  root: {
    // fontFamily: theme.typography.body2.fontFamily,
    margin: '30px 0',
    '& .MuiStep-vertical .MuiStepLabel-vertical svg': {
      width: '34px',
      height: '34px',
    },
    '& .Mui-active circle': { color: '#78CC8A' },
    '& .MuiStepIcon-completed': { color: '#78CC8A' },
    '& .Mui-disabled .MuiStepIcon-root': {
      color: '#fff',
      border: '1.8px solid #A0D3AB',
      borderRadius: '20px',
    },
    '& .Mui-disabled .MuiStepIcon-root text': { fill: '#78CC8A' },
    '& .MuiStepLabel-labelContainer span': {
      fontFamily: theme.typography.body2.fontFamily,
      fontSize: '16px',
      color: '#78CC8A',
    },
    '& .MuiStepConnector-vertical span ': {
      borderColor: '#78CC8A',
      marginLeft: '5px',
    },
  },
}));
