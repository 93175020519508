import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { royaltyOwnerForm } from 'libs/organization/logic/src/register/royaltyOwner';
import { useRoyaltyOwnerRegisterHandler } from '@energyweb/origin-ui-organization-data';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

export const useRoyaltyOwnerEffects = (project: ProjectDTO) => {
  const { t } = useTranslation();

  const {
    initialValues,
    validationSchema,
    fields,
    validationMode,
    formInputsProps,
  } = royaltyOwnerForm(t, project);

  const { submitHandler } = useRoyaltyOwnerRegisterHandler(project);
  const { register, handleSubmit, formState, reset } = useForm({
    mode: validationMode,
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const { isValid, errors, dirtyFields } = formState;
  const royaltyOwners = project?.ownership?.royaltyOwners || [];
  return {
    errors,
    isValid,
    formInputsProps,
    initialValues,
    dirtyFields,
    fields,
    royaltyOwners,
    reset,
    handleSubmit,
    submitHandler,
    register,
  };
};
