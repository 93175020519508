import { makeStyles } from '@mui/styles';
import { LightenColor } from '@energyweb/origin-ui-theme';

export const useStyles = makeStyles((theme) => ({
  logo: {
    margin: '20px',
  },
  logobar: {
    backgroundColor: '#fff',
    width: 355,
    textAlign: 'center',
  },
  toolbar: {
    position: 'fixed',
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  profileIcon: {
    height: 40,
    width: 40,
    marginRight: 24,
  },
  menuIcon: {
    height: 24,
    width: 24,
    marginRight: 24,
  },
  button: {
    width: 'fit-content',
    color: LightenColor(theme.palette?.text.secondary, 5, theme.palette?.mode),
    '&:hover': {
      backgroundColor: LightenColor(
        theme.palette?.background.paper,
        10,
        theme.palette?.mode
      ),
    },
  },
}));
