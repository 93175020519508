import { GenericFormProps } from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export type TUserSignInFormValues = {
  firstName: string;
  lastName: string;
  telephone: string;
  email: string;
  password: string;
  repeatPassword: string;
  termsAndConditions: boolean;
};

const INITIAL_FORM_VALUES: TUserSignInFormValues = {
  firstName: '',
  lastName: '',
  telephone: '',
  email: '',
  password: '',
  repeatPassword: '',
  termsAndConditions: false,
};

export const useUserSignInFormConfig = (
  formSubmitHandler: GenericFormProps<TUserSignInFormValues>['submitHandler']
): GenericFormProps<TUserSignInFormValues> => {
  const { t } = useTranslation();
  return {
    buttonFullWidth: true,
    buttonText: t('general.buttons.register'),
    validationMode: 'onSubmit',
    fields: [
      {
        label: '',
        name: 'firstName',
        required: true,
        inputProps: {
          ['data-cy']: 'firstName',
          placeholder: t('user.register.firstName'),
        },
      },
      {
        label: '',
        name: 'lastName',
        required: true,
        inputProps: {
          ['data-cy']: 'lastName',
          placeholder: t('user.register.lastName'),
        },
      },
      {
        label: '',
        name: 'email',
        required: true,
        inputProps: {
          ['data-cy']: 'email',
          placeholder: t('user.register.email'),
        },
      },
      {
        label: '',
        name: 'telephone',
        required: true,
        inputProps: {
          ['data-cy']: 'telephone',
          placeholder: t('user.register.telephone'),
        },
      },
      {
        type: 'password',
        label: '',
        name: 'password',
        required: true,
        inputProps: {
          ['data-cy']: 'password',
          placeholder: t('user.register.password'),
        },
      },
      {
        type: 'password',
        label: '',
        name: 'repeatPassword',
        required: true,
        inputProps: {
          ['data-cy']: 'repeatPassword',
          placeholder: t('user.register.repeatPassword'),
        },
      },
      {
        checkbox: true,
        label: t('user.register.termsAndConditions'),
        name: 'termsAndConditions',
        required: true,
        additionalCheckboxProps: {
          hasLink: true,
          linkTo: '/',
          linkText: t('user.register.termsAndConditionsLinkText'),
          tooltip: t('user.register.termsAndConditionsTooltip'),
        },
        inputProps: {
          ['data-cy']: 'termsAndConditions',
        },
      },
    ],
    buttonWrapperProps: { justifyContent: 'flex-start' },
    buttonProps: { ['data-cy']: 'register-button' },
    initialValues: INITIAL_FORM_VALUES,
    submitHandler: formSubmitHandler,
    inputsVariant: 'filled',
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .min(1)
        .max(25)
        .matches(/^\w+$/, t('user.register.firstNameValidation'))
        .label(t('user.register.firstName'))
        .required(),
      lastName: Yup.string()
        .min(1)
        .max(25)
        .matches(/^\w+$/, t('user.register.lastNameValidation'))
        .label(t('user.register.lastName'))
        .required(),
      email: Yup.string().email().label(t('user.register.email')).required(),
      telephone: Yup.string()
        .required()
        .min(10)
        .matches(
          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
          t('user.register.phoneValidation')
        )
        .label(t('user.register.telephone')),
      password: Yup.string()
        .required()
        .matches(
          /((?=.*[0-9])(?=.*[a-z]).{6,})/,
          t('user.register.passwordValidation')
        )
        .label(t('user.register.password')),
      repeatPassword: Yup.string().oneOf(
        [Yup.ref('password'), null],
        t('user.register.repeatPasswortValidation')
      ),
      termsAndConditions: Yup.boolean()
        .oneOf([true], t('user.register.termsAndConditionsValidation'))
        .required(),
    }),
  };
};
