import {
  getUserControllerMeQueryKey,
  UserDTO,
  UserStatus,
  UserAccountDTO,
  useUserBankAccountControllerCreateAccount,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { UseFormReset } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';

export type TCreateUserAccountFormValues = {
  name: UserAccountDTO['name'];
  IBAN: UserAccountDTO['IBAN'];
  creditCard: UserAccountDTO['creditCard'];
  debitCard: UserAccountDTO['debitCard'];
};

export const useApiCreateUserBankAccountData = () => {
  const { t } = useTranslation();

  const { mutate } = useUserBankAccountControllerCreateAccount();

  const queryClient = useQueryClient();
  const userQueryKey = getUserControllerMeQueryKey();
  const navigate = useNavigate();

  const submitHandler = (
    values: TCreateUserAccountFormValues,
    resetForm: UseFormReset<TCreateUserAccountFormValues>
  ) => {
    const user: UserDTO = queryClient.getQueryData(userQueryKey);
    const { status } = user;

    if (status !== UserStatus.Active) {
      showNotification(
        t('user.profile.notifications.onlyActiveUserCan', {
          status: user.status,
        }),
        NotificationTypeEnum.Error
      );
      return;
    }

    mutate(
      { data: { ...values } },
      {
        onSuccess: () => {
          showNotification(
            t('user.account.success'),
            NotificationTypeEnum.Success
          );
          queryClient.resetQueries(userQueryKey);
          navigate('/account/profile');
        },
        onError: (error: any) => {
          showNotification(
            `${t('user.account.error')}:
              ${error?.response?.data?.message || ''}
              `,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };

  return {
    submitHandler,
  };
};
