import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Paper, Typography } from '@mui/material';
import { TableComponent } from '@energyweb/origin-ui-core';
import { useStyles } from './RoyaltyOwnersListingPage.styles';
import { useRoyaltyOwnersListingEffects } from './RoyaltyOwnersListingPage.effects';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { EmailOutlined } from '@mui/icons-material';
import { RoyaltyOwnerDTO } from '@energyweb/origin-backend-react-query-client';

export interface RoyaltyOwnersProps {
  project?: ProjectDTO;
}

export const RoyaltyOwnersListingPage: FC<RoyaltyOwnersProps> = ({
  project,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { royaltyOwners, header, actions, onSubmit } =
    useRoyaltyOwnersListingEffects(project);

  return (
    <div className={classes.root}>
      <Paper classes={{ root: classes.paper }}>
        <TableComponent
          header={header}
          tableTitle={t('project.royaltyOwnerListing.title')}
          data={
            royaltyOwners
              ? royaltyOwners.map(
                  (royaltyOwner: RoyaltyOwnerDTO, i: number) => ({
                    id: royaltyOwner.id || i,
                    name: royaltyOwner.name,
                    ownershipType: royaltyOwner.ownershipType,
                    email: royaltyOwner.email,
                    status: royaltyOwner.status,
                    actions,
                  })
                )
              : []
          }
          editable={true}
        />
        <Typography className={classes.warningText}>
          {t('project.royaltyOwnerListing.warningText')}
        </Typography>
        <Button
          color="primary"
          name="submit"
          size="large"
          variant="outlined"
          type="button"
          onClick={() => onSubmit(project.id)}
          endIcon={<EmailOutlined />}
        >
          {t('project.royaltyOwnerListing.confirmationRequest')}
        </Button>
      </Paper>
    </div>
  );
};

export default RoyaltyOwnersListingPage;
