import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  paper: {
    background: '#FEFFFF',
    padding: 35,
  },
  form: {
    margin: '24px 0',
    '& .MuiFormControl-root': {
      '& label': {
        color: '#2B201F',
      },
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#AAB0F3',
      },
      '& .Mui-focused fieldset': {
        borderColor: '#AAB0F3',
      },
    },
  },
});
