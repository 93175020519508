import {
  RiskOption,
  RiskType,
} from '@energyweb/origin-backend-react-query-client';
import { FormSelectOption } from '@energyweb/origin-ui-core';

export const prepairRiskOptions = (
  riskTypes: RiskType[],
  riskTypeId: number
): FormSelectOption[] => {
  if (!riskTypeId) {
    return [];
  }

  const riskType = riskTypes?.find(
    (riskType: any) => riskType.id === riskTypeId
  );
  const RISK_OPTIONS: FormSelectOption[] = riskType?.options.map(
    (option: RiskOption) => ({
      value: option.id,
      label: option.name,
    })
  );

  return RISK_OPTIONS;
};
