import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  list: {
    padding: 0,
    '& .MuiListItem-root': {
      fontSize: 15,
    },
  },
});
