import { getProjectControllerGetQueryKey } from '@energyweb/origin-organization-irec-api-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  ProjectConfirmationDTO,
  ProjectDTO,
  useOwnershipControllerConfirmValidation,
} from '@energyweb/origin-backend-react-query-client';

export const useRequestConfirmationHandler = (project?: ProjectDTO) => {
  const { t } = useTranslation();
  const { mutate } = useOwnershipControllerConfirmValidation();

  const storedData = JSON.parse(localStorage.getItem('project-data')) || [
    project,
  ];
  const projectId = storedData[0]?.id;
  const queryClient = useQueryClient();
  const projectQueryKey = getProjectControllerGetQueryKey(projectId);

  const submitHandler = (values: ProjectConfirmationDTO) => {
    mutate(
      { data: { ...values } },
      {
        onSuccess: (result) => {
          showNotification(
            t('project.register.notifications.requestConfirmationSuccess'),
            NotificationTypeEnum.Success
          );
          queryClient.invalidateQueries(projectQueryKey);
          localStorage.setItem(
            'project-data',
            JSON.stringify([...storedData, result])
          );
        },
        onError: (error: any) => {
          showNotification(
            `${t('project.register.notifications.requestConfirmationError')}:
            ${error?.response?.data?.message || ''}`,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };

  return { submitHandler };
};
