import {
  ProjectDTO,
  CreateCarbonContentDTO,
  getProjectControllerGetQueryKey,
  useCarbonContentControllerCreate,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { __values } from 'tslib';

export const useRegisterCarbonContentHandler = (project?: ProjectDTO) => {
  const { t } = useTranslation();
  const { mutate } = useCarbonContentControllerCreate();

  const storedData = JSON.parse(localStorage.getItem('project-data')) || [
    project,
  ];
  const projectId = storedData[0]?.id;

  const queryClient = useQueryClient();
  const projectQueryKey = getProjectControllerGetQueryKey(projectId);

  const submitHandler = (values: CreateCarbonContentDTO) => {
    values.projectId = projectId;

    mutate(
      { data: { ...values } },
      {
        onSuccess: (result) => {
          showNotification(
            t('project.register.notifications.carbonContentRegisteredSuccess'),
            NotificationTypeEnum.Success
          );
          queryClient.invalidateQueries(projectQueryKey);
          localStorage.setItem(
            'project-data',
            JSON.stringify([...storedData, result])
          );
        },
        onError: (error: any) => {
          if (error?.response?.status === 400) {
            showNotification(
              error?.response?.data?.message,
              NotificationTypeEnum.Error
            );
          } else {
            showNotification(
              `${t(
                'project.register.notifications.carbonContentRegisteredFailure'
              )}:
                ${error?.response?.data?.message || ''}
                `,
              NotificationTypeEnum.Error
            );
          }
        },
      }
    );
  };
  return { submitHandler };
};
