import React from 'react';
import * as yup from 'yup';
import { TRegisterCarbonForm } from './types';
import { OIL_GAS_PROJECT_TYPES } from '../select-options/projectTypes';
import { CarbonContentForm } from '@energyweb/origin-ui-organization-view';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';

const CarbonContentFormWrapper = (project: ProjectDTO) => (props: any) =>
  <CarbonContentForm {...props} project={project} />;

export const registerCarbonForm: TRegisterCarbonForm = (
  t,
  project?: ProjectDTO
) => {
  const currentStepData = project?.carbonContent;

  return {
    formTitle: t('project.register.carbonContentTitle'),
    inputsVariant: 'outlined',
    initialValues: {
      type: currentStepData?.type || '',
      gasHeatingValue: currentStepData?.gasHeatingValue || 0,
      oilApiGravity: currentStepData?.oilApiGravity || 0,
      ethane: currentStepData?.ethane || 0,
      propane: currentStepData?.propane || 0,
      isoButane: currentStepData?.isoButane || 0,
      nButane: currentStepData?.nButane || 0,
      isoPentane: currentStepData?.isoPentane || 0,
      nPentane: currentStepData?.nPentane || 0,
      hexanes: currentStepData?.hexanes || 0,
      gasShrinkage: currentStepData?.gasShrinkage || 0,
      uploads: currentStepData?.uploads || [],
      projectId: project?.id,
    },
    validationSchema: yup.object().shape({
      type: yup
        .string()
        .required()
        .label(t('project.register.carbonContent.type')),
      gasHeatingValue: yup
        .number()
        .min(0)
        .required()
        .label(t('project.register.carbonContent.gasHeatingValue')),
      oilApiGravity: yup
        .number()
        .min(0)
        .required()
        .label(t('project.register.carbonContent.oilApiGravity')),
      gasShrinkage: yup
        .number()
        .min(0)
        .required()
        .label(t('project.register.carbonContent.gasShrinkage')),
      ethane: yup
        .number()
        .min(0)
        .max(100)
        .label(t('project.register.carbonContent.ethane')),
      propane: yup
        .number()
        .min(0)
        .max(100)
        .label(t('project.register.carbonContent.propane')),
      isoButane: yup
        .number()
        .min(0)
        .max(100)
        .label(t('project.register.carbonContent.isoButane')),
      nButane: yup
        .number()
        .min(0)
        .max(100)
        .label(t('project.register.carbonContent.nButane')),
      isoPentane: yup
        .number()
        .min(0)
        .max(100)
        .label(t('project.register.carbonContent.isoPentane')),
      nPentane: yup
        .number()
        .min(0)
        .max(100)
        .label(t('project.register.carbonContent.nPentane')),
      hexanes: yup
        .number()
        .min(0)
        .max(100)
        .label(t('project.register.carbonContent.hexanes')),
    }),
    fields: [
      {
        name: 'type',
        label: t('project.register.carbonContent.type'),
        select: true,
        options: OIL_GAS_PROJECT_TYPES,
        required: true,
        inputProps: { ['data-cy']: 'type' },
        helperText: 'Select an option',
      },
      {
        name: 'oilApiGravity',
        label: t('project.register.carbonContent.oilApiGravity'),
        required: true,
        inputProps: { ['data-cy']: 'oilApiGravity' },
        startAdornment: 'Oil API Gravity',
        placeholder: 'Oil API Gravity',
        helperText: 'Must add up to 1 for data validation',
      },
      {
        name: 'gasHeatingValue',
        label: t('project.register.carbonContent.gasHeatingValue'),
        required: true,
        inputProps: { ['data-cy']: 'gasHeatingValue' },
        startAdornment: 'Gas Heating Value',
        placeholder: 'Gas Heating Value',
        helperText: 'Must add up to 1 for data validation',
      },
      {
        name: 'ethane',
        label: t('project.register.carbonContent.ethane'),
        required: false,
        startAdornment: 'Ethane',
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'ethane' },
        placeholder: 'Ethane',
      },
      {
        name: 'propane',
        label: t('project.register.carbonContent.propane'),
        required: false,
        startAdornment: 'Propane',
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'propane' },
        placeholder: 'Propane',
      },
      {
        name: 'isoButane',
        label: t('project.register.carbonContent.isoButane'),
        required: false,
        startAdornment: 'Iso-Butane',
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'isoButane' },
        placeholder: 'Iso-Butane',
      },
      {
        name: 'nButane',
        label: t('project.register.carbonContent.nButane'),
        required: false,
        startAdornment: 'N-Butane',
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'nButane' },
        placeholder: 'N-Butane',
      },
      {
        name: 'isoPentane',
        label: t('project.register.carbonContent.isoPentane'),
        required: false,
        startAdornment: 'Iso-Pentane',
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'isoPentane' },
        placeholder: 'Iso-Pentane',
      },
      {
        name: 'nPentane',
        label: t('project.register.carbonContent.nPentane'),
        required: false,
        startAdornment: 'N-Butane',
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'nPentane' },
        placeholder: 'N-Butane',
      },
      {
        name: 'hexanes',
        label: t('project.register.carbonContent.hexanes'),
        required: false,
        startAdornment: 'Hexanes+',
        endAdornment: { isValidCheck: false, element: '%' },
        inputProps: { ['data-cy']: 'hexanes' },
        placeholder: 'Hexanes+',
        helperText: 'Must add up to 1 for data validation',
      },
      {
        name: 'gasShrinkage',
        label: t('project.register.carbonContent.gasShrinkage'),
        required: true,
        startAdornment: 'Gas Shrinkage in Operations',
        inputProps: { ['data-cy']: 'gasShrinkage' },
        placeholder: 'Gas Shrinkage in Operations',
        helperText: 'Must add up to 1 for data validation',
      },
    ],
    buttonText: t('general.buttons.save'),
    customStep: true,
    component: CarbonContentFormWrapper(project),
  };
};
