import React, { memo, PropsWithChildren, ReactElement } from 'react';
import {
  Tooltip,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Link,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Control, Controller } from 'react-hook-form';

export interface AdditionalCheckboxProps {
  hasLink: boolean;
  linkText?: string;
  linkTo?: string;
  tooltip?: string;
}

export type FormCheckboxField<FormValuesType> = {
  name: keyof FormValuesType;
  label: string;
  checked?: boolean;
  disabled?: boolean;
  required?: boolean;
};

export interface FormCheckboxProps<FormValuesType> {
  field: FormCheckboxField<FormValuesType>;
  control: Control<FormValuesType>;
  additionalProps?: AdditionalCheckboxProps;
  errorExists?: boolean;
  errorText?: string;
  disabled?: boolean;
}

export type TFormCheckbox = <FormValuesType>(
  props: PropsWithChildren<FormCheckboxProps<FormValuesType>>
) => ReactElement;

export const FormCheckbox: TFormCheckbox = memo(
  ({
    field,
    control,
    additionalProps = {
      hasLink: false,
    },
    errorExists = false,
    errorText = '',
    disabled = false,
  }) => {
    return (
      <div>
        <FormControlLabel
          control={
            <Controller
              name={field.name as any}
              control={control}
              render={({ field }) => (
                <Checkbox checkedIcon={<CheckIcon />} {...field} />
              )}
            />
          }
          label={
            <div>
              <span>{field.label}</span>
              {additionalProps.hasLink && (
                <Tooltip
                  placement="top"
                  title={additionalProps.tooltip ? additionalProps.tooltip : ''}
                >
                  <Link href={additionalProps.linkTo}>
                    {additionalProps.linkText}
                  </Link>
                </Tooltip>
              )}
            </div>
          }
        />
        {errorExists && <FormHelperText error>{errorText}</FormHelperText>}
      </div>
    );
  }
);
