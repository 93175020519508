import React, { ReactNode } from 'react';
import { useStyles } from './PageWrapper.styles';
import { useUser } from '@energyweb/origin-ui-user-data';
import { isRole, Role } from '@energyweb/origin-backend-core';
import { useLocation } from 'react-router-dom';

export interface PageWrapperProps {
  children: ReactNode;
}

export const PageWrapper = ({ children }: PageWrapperProps) => {
  const classes = useStyles();
  const location = useLocation();
  const exceptions = ['/organization/register'];
  const { user } = useUser();
  const userIsProjectOwner = isRole(user, Role.ProjectOwner);

  const getWrapperClass =
    classes.wrapper +
    (exceptions.includes(location.pathname) && userIsProjectOwner
      ? ' pd-small'
      : '');
  return <div className={getWrapperClass}>{children}</div>;
};
