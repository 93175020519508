import React, { FC } from 'react';
import {
  ButtonsGroup,
  FileUpload,
  FormInput,
  FormSelect,
  GenericFormSecondaryButton,
} from '@energyweb/origin-ui-core';
import { ReservesValidationFormValues } from '@energyweb/origin-ui-organization-logic';
import { Box, Grid, Typography } from '@mui/material';
import { useStyles } from './ReservesValidation.styles';
import { UnpackNestedValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useReservesValidationEffects } from './ReservesValidation.effects';
import { fileControllerUploadToIPFS } from '@energyweb/origin-backend-react-query-client';
import { Upload } from '@energyweb/origin-backend-core';
import { isEmpty } from 'lodash';
import { ProjectDTO } from '@energyweb/issuer-irec-api-react-query-client';

export interface ReservesValidationProps {
  submitHandler?: (
    values: UnpackNestedValue<ReservesValidationFormValues>
  ) => Promise<void>;
  secondaryButtons?: GenericFormSecondaryButton[];
  loading?: boolean;
  project?: ProjectDTO;
}

export const ReservesValidationForm: FC<ReservesValidationProps> = ({
  submitHandler,
  secondaryButtons,
  loading,
  project,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    documents,
    fields,
    register,
    handleSubmit,
    control,
    errors,
    uploadFunction,
    onChange,
  } = useReservesValidationEffects(project);

  const onSubmit = async (values: ReservesValidationFormValues) => {
    const files = documents.filter(
      (uploadedFile: File) =>
        !values.uploads.some((file: Upload) => file.name === uploadedFile.name)
    );
    if (files.length > 0) {
      const uploadedFiles: Upload[] = await fileControllerUploadToIPFS({
        files,
      });
      values.uploads = [...values.uploads, ...uploadedFiles];
    }
    submitHandler(values);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        {fields.map(
          (field: any, index: number) =>
            (field.select && (
              <Grid my={1} key={index}>
                <Typography variant="subtitle1">{field.label}</Typography>
                <FormSelect
                  key={field.label}
                  field={field}
                  control={control}
                  errorExists={!isEmpty((errors as any)[field.name])}
                  errorText={(errors as any)[field.name]?.message ?? ''}
                  variant={'outlined'}
                  disabled={false}
                  register={register}
                />
              </Grid>
            )) ||
            (field.file && (
              <FileUpload
                heading={field.label}
                dropzoneText={t('file.upload.dropOrClick')}
                apiUploadFunction={uploadFunction}
                onChange={onChange}
                wrapperProps={{ ['data-cy']: 'reservesReport' }}
              />
            )) || (
              <Grid my={1} key={index}>
                <Typography variant="subtitle1">{field.label}</Typography>
                <FormInput
                  key={field.label}
                  field={field}
                  disabled={true}
                  register={register}
                  errorExists={!isEmpty((errors as any)[field.name])}
                  errorText={(errors as any)[field.name]?.message ?? ''}
                  variant={'outlined'}
                  {...field.inputProps}
                />
              </Grid>
            )
        )}
        <ButtonsGroup secondaryButtons={secondaryButtons} loading={loading} />
      </form>
    </Box>
  );
};
