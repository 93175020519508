import {
  usePhoneVerificationControllerCreateVerificationCode,
  usePhoneVerificationControllerVerifyUserPhone,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useQueryClient } from 'react-query';

export interface VerifyPhoneFormValues {
  code: string;
  phone?: string;
}

export interface RequestPhoneVerification {
  phone: string;
}

export const useRequestPhoneVerification = () => {
  const { mutate, isLoading: isMutating } =
    usePhoneVerificationControllerCreateVerificationCode();
  const { t } = useTranslation();

  const requestHandler = (values: RequestPhoneVerification) => {
    mutate(
      { data: { phone: values.phone } },
      {
        onSuccess: () => {
          showNotification(
            t('user.verifyPhone.notifications.requestCodeSuccess'),
            NotificationTypeEnum.Success
          );
        },
        onError: (error: any) => {
          showNotification(
            `${t('user.verifyPhone.notifications.requestError')}: ${
              error?.response?.data?.message || ''
            }`,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };
  return { requestHandler, isMutating };
};

export const useVerifyPhoneHandler = () => {
  const { mutate, isLoading: isMutating } =
    usePhoneVerificationControllerVerifyUserPhone();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const verifyHandler = (values: VerifyPhoneFormValues) => {
    mutate(
      { data: { phone: values.phone, code: values.code } },
      {
        onSuccess: () => {
          showNotification(
            t('user.verifyPhone.notifications.success'),
            NotificationTypeEnum.Success
          );
          queryClient.resetQueries();
          navigate('/');
        },
        onError: (error: any) => {
          showNotification(
            `${t('user.verifyPhone.notifications.error')}`,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };

  return { verifyHandler, isMutating };
};
