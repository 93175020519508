import {
  ProjectType,
  OilGasProjectType,
  WellType,
} from '@energyweb/origin-backend-core';

export const PROJECT_CARBON_TYPES_OPTIONS = [
  ...Object.values(ProjectType).map((item) => ({
    value: item,
    label: item,
  })),
];

export const OIL_GAS_PROJECT_TYPES = [
  ...Object.values(OilGasProjectType).map((item) => ({
    value: item,
    label: item,
  })),
];

export const WELL_TYPE_OPTIONS = [
  ...Object.values(WellType).map((item) => ({
    value: item,
    label: item,
  })),
];
