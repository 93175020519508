import React from 'react';
import { Box, Button } from '@mui/material';
import { ArrowBack, ArrowForward, Save } from '@mui/icons-material';
import { FC } from 'react';
import { useStyles } from './ButtonsGroup.styles';
import { useTranslation } from 'react-i18next';

export interface ButtonsGroupProps {
  backButtonExists?: boolean;
  secondaryButtons: any;
  loading: boolean;
}

export const ButtonsGroup: FC<ButtonsGroupProps> = ({
  backButtonExists = true,
  secondaryButtons,
  loading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box mt={3} className={classes.container}>
      <Box justifyContent="flex-start">
        {backButtonExists && (
          <Button
            size="large"
            startIcon={<ArrowBack />}
            {...secondaryButtons[0]}
          >
            {t('general.buttons.back')}
          </Button>
        )}
        <Button color="primary" name="cancel" size="large" variant="outlined">
          {t('general.buttons.cancel')}
        </Button>
      </Box>
      <Box justifyContent="flex-end">
        <Button
          color="primary"
          name="submit"
          size="large"
          variant="outlined"
          disabled={loading}
          type="submit"
          startIcon={<Save />}
        >
          {t('general.buttons.save')}
        </Button>
        <Button
          size="large"
          endIcon={<ArrowForward />}
          {...secondaryButtons[1]}
        >
          {t('general.buttons.next')}
        </Button>
      </Box>
    </Box>
  );
};
