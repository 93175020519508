import React from 'react';
import { useTranslation } from 'react-i18next';
import { Edit } from '@mui/icons-material';
import { TableActionData } from '@energyweb/origin-ui-core';
import {
  ProjectDTO,
  RoyaltyOwner,
  royaltyOwnerControllerUpdate,
} from '@energyweb/origin-backend-react-query-client';
import { useRequestConfirmationHandler } from '@energyweb/origin-ui-organization-data';

export const useRoyaltyOwnersListingEffects = (project: ProjectDTO) => {
  const { t } = useTranslation();
  const royaltyOwners = project?.ownership?.royaltyOwners || [];
  const { submitHandler } = useRequestConfirmationHandler();

  const actions: TableActionData<RoyaltyOwner['id']>[] = [
    {
      icon: <Edit data-cy="editIcon" />,
      name: t('general.buttons.edit'),
      onClick: async (id, data) => {
        data.ownershipId = project?.ownership?.id;
        await royaltyOwnerControllerUpdate(id, data);
      },
    },
  ];

  const header = {
    id: 'Id',
    name: t('project.royaltyOwnerListing.name'),
    ownershipType: t('project.royaltyOwnerListing.type'),
    email: t('project.royaltyOwnerListing.email'),
    status: t('project.royaltyOwnerListing.status'),
    actions: 'Actions',
  };

  const onSubmit = (projectId: any) => {
    if (!projectId) return false;
    submitHandler({ projectId });
  };

  return {
    royaltyOwners,
    header,
    actions,
    onSubmit,
  };
};
