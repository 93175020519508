import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { UploadedFile } from '@energyweb/origin-ui-core';
import { ownershipForm } from 'libs/organization/logic/src/register/ownership';
import { ProjectDTO } from '@energyweb/origin-backend-react-query-client';
import { useRiskTypesData } from '@energyweb/origin-ui-organization-data';
import { prepairRiskOptions } from 'libs/organization/logic/src/select-options';

export const useOwnershipEffects = (project?: ProjectDTO) => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<File[]>([]);
  const { riskTypes } = useRiskTypesData();

  const { initialValues, validationSchema, fields, validationMode } =
    ownershipForm(t, project);
  const [_fields, setFields] = useState<any>(fields);

  const { register, handleSubmit, formState, control, watch, setValue } =
    useForm({
      mode: 'onChange',
      resolver: yupResolver(validationSchema),
      defaultValues: initialValues,
    });

  const { errors } = formState;
  const { riskTypeId, riskOptionId } = watch();

  const riskTypeIndex = fields.findIndex(
    (field) => field.name === 'riskTypeId'
  );
  const riskOptionIndex = fields.findIndex(
    (field) => field.name === 'riskOptionId'
  );

  useEffect(() => {
    fields[riskTypeIndex].options = riskTypes?.map((riskType) => ({
      label: riskType.name,
      value: riskType.id,
    }));

    const riskOptions = prepairRiskOptions(riskTypes, riskTypeId);
    fields[riskOptionIndex].options = riskOptions;
    setFields([...fields]);
  }, [riskTypes, riskTypeId]);

  const uploadFunction = async (file: File[]) => {
    const fileIndex = documents.findIndex(
      (existingFile: File) => existingFile.name == file[0]?.name
    );
    if (fileIndex === -1) {
      setDocuments((prev: File[]) => [...prev, ...file]);
    }
    return documents;
  };

  const onChange = (file: UploadedFile[]) => {
    if (file[0]?.removed) {
      const files = documents.filter(
        (document: File) => document.name !== file[0].uploadedName
      );
      setDocuments(files);
    }
  };

  return {
    initialValues,
    validationSchema,
    fields: _fields,
    validationMode,
    documents,
    control,
    errors,
    register,
    handleSubmit,
    uploadFunction,
    onChange,
  };
};
