import {
  CreateRoyaltyOwnerDTO,
  getProjectControllerGetQueryKey,
  ownershipControllerCreate,
  ProjectDTO,
  useRoyaltyOwnerControllerRegister,
} from '@energyweb/origin-backend-react-query-client';
import {
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';

import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export const useRoyaltyOwnerRegisterHandler = (project: ProjectDTO) => {
  const { t } = useTranslation();
  const { mutate } = useRoyaltyOwnerControllerRegister();

  const queryClient = useQueryClient();
  const storedData = JSON.parse(localStorage.getItem('project-data')) || [
    project,
  ];
  const projectKey = getProjectControllerGetQueryKey(storedData[0]?.id);

  const submitHandler = async (values: CreateRoyaltyOwnerDTO) => {
    const formattedValues: CreateRoyaltyOwnerDTO = {
      ...values,
    };
    if (!project?.ownership?.id) {
      const ownership = await ownershipControllerCreate({
        riskOptionId: 1,
        uploads: [],
        projectId: storedData[0]?.id,
      });
      if (ownership?.id) {
        formattedValues.ownershipId = ownership.id;
        localStorage.setItem(
          'project-data',
          JSON.stringify([...storedData, ownership])
        );
      }
    }
    mutate(
      { data: formattedValues },
      {
        onSuccess: () => {
          showNotification(
            t('project.register.notifications.royaltyOwnerRegisteredSuccess'),
            NotificationTypeEnum.Success
          );
          queryClient.invalidateQueries(projectKey);
        },
        onError: (error: any) => {
          if (error?.response?.status === 400) {
            showNotification(
              error?.response?.data?.message,
              NotificationTypeEnum.Error
            );
          } else {
            showNotification(
              `${t(
                'project.register.notifications.royaltyOwnerRegisteredFailure'
              )}:
                  ${error?.response?.data?.message || ''}
                  `,
              NotificationTypeEnum.Error
            );
          }
        },
      }
    );
  };

  return { submitHandler };
};
