import {
  ProjectVerifierDTO,
  ProjectVerifierStatus,
  RegisterProjectVerifierDTO,
} from '@energyweb/origin-backend-react-query-client';
import { GenericFormProps } from '@energyweb/origin-ui-core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UsaStates } from 'usa-states';
import * as Yup from 'yup';
import { useVerifierCompaniesOptions } from '@energyweb/origin-ui-utils';
import { useVerifierCompanies } from '@energyweb/origin-ui-user-data';

export type TUseProjectVerifierFormConfig = (
  data: ProjectVerifierDTO,
  isLoading: boolean
) => Omit<GenericFormProps<RegisterProjectVerifierDTO>, 'submitHandler'>;

const usStates = new UsaStates();
const statesNames = usStates.arrayOf('names');
const STATES_OPTION = statesNames.map((state) => ({
  label: state,
  value: state,
}));

export const useProjectVerifierFormConfig: TUseProjectVerifierFormConfig = (
  projectVerifier: ProjectVerifierDTO,
  isLoading: boolean
) => {
  const { t } = useTranslation();
  const { company, state, license, email, status } = projectVerifier || {};

  const [buttonText, setButtonText] = useState<string>(
    t('general.buttons.save')
  );
  const { verifierCompanies } = useVerifierCompanies();
  const { VERIFIER_COMPANY_OPTIONS } =
    useVerifierCompaniesOptions(verifierCompanies);

  useEffect(() => {
    if (status === 'Pending') setButtonText(t('general.buttons.pending'));
    if (status === 'Rejected') setButtonText(t('general.buttons.rejected'));
    if (status === 'Accepted') setButtonText(t('general.buttons.accepted'));
  }, [status, isLoading]);

  return {
    formTitle: t('user.projectVerifier.formTitle'),
    buttonText,
    fields: [
      {
        label: t('user.projectVerifier.company'),
        name: 'companyId',
        required: true,
        select: true,
        options: VERIFIER_COMPANY_OPTIONS,
        inputProps: {
          ['data-cy']: 'companyId',
        },
        placeholder: 'Select your company',
      },
      {
        label: t('user.projectVerifier.email'),
        name: 'email',
        required: true,
        inputProps: {
          ['data-cy']: 'email',
        },
        placeholder: 'Enter your email',
      },
      {
        name: 'state',
        label: t('user.projectVerifier.state'),
        select: true,
        options: STATES_OPTION,
        required: true,
        inputProps: { ['data-cy']: 'state' },
      },
      {
        label: t('user.projectVerifier.license'),
        name: 'license',
        required: true,
        inputProps: {
          ['data-cy']: 'license',
        },
        placeholder: 'Enter the license number',
      },
    ],
    buttonWrapperProps: { justifyContent: 'center' },
    initialValues: {
      companyId: company?.id || null,
      email: email || '',
      state: state || '',
      license: license || '',
      status: ProjectVerifierStatus.Pending,
    },
    loading: isLoading,
    inputsVariant: 'outlined' as any,
    validationSchema: Yup.object().shape({
      companyId: Yup.number()
        .label(t('user.projectVerifier.company'))
        .required(),
      email: Yup.string()
        .label(t('user.projectVerifier.email'))
        .required()
        .email(),
      state: Yup.string().label(t('user.projectVerifier.state')).required(),
      license: Yup.string()
        .label(t('user.projectVerifier.license'))
        .required()
        .max(200),
    }),
  };
};
