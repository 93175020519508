import {
  ButtonsGroup,
  FormSelect,
  GenericFormSecondaryButton,
  FileUpload,
  FormInput,
} from '@energyweb/origin-ui-core';
import {
  fileControllerUploadToIPFS,
  ProjectDTO,
} from '@energyweb/origin-backend-react-query-client';
import { RegisterCarbonFormValues } from '@energyweb/origin-ui-organization-logic';
import { Box, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useStyles } from './CarbonContent.styles';
import { UnpackNestedValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCarbonContentEffects } from './CarbonContent.effects';
import { isEmpty } from 'lodash';
import { Upload } from '@energyweb/origin-backend-core';

export interface CarbonContentProps {
  submitHandler?: (
    values: UnpackNestedValue<RegisterCarbonFormValues>
  ) => Promise<void>;
  secondaryButtons?: GenericFormSecondaryButton[];
  loading?: boolean;
  project?: ProjectDTO;
}

export const CarbonContentForm: FC<CarbonContentProps> = ({
  submitHandler,
  secondaryButtons,
  loading,
  project,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    errors,
    control,
    dirtyFields,
    selectField,
    firstColumn,
    secondColumn,
    gasShrinkageField,
    documents,
    onChange,
    uploadFunction,
    handleSubmit,
    register,
  } = useCarbonContentEffects(project);

  const onSubmit = async (values: RegisterCarbonFormValues) => {
    const files = documents.filter(
      (uploadedFile: File) =>
        !values.uploads.some((file: Upload) => file.name === uploadedFile.name)
    );
    if (files.length > 0) {
      const uploadedFiles: Upload[] = await fileControllerUploadToIPFS({
        files,
      });
      values.uploads = [...values.uploads, ...uploadedFiles];
    }
    submitHandler(values);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Grid container>
          <Grid item xs={12} md={12}>
            {selectField && (
              <>
                <Typography variant="subtitle1">
                  Type of Oil &amp; Gas project
                </Typography>
                <FormSelect
                  key={selectField.label}
                  field={selectField}
                  control={control}
                  errorExists={!isEmpty((errors as any)[selectField.name])}
                  errorText={(errors as any)[selectField.name]?.message ?? ''}
                  variant={'outlined'}
                  disabled={false}
                  register={register}
                />
              </>
            )}
          </Grid>
          <Grid container spacing={2} className={classes.block}>
            <Grid item xs={12} md={6}>
              {firstColumn.map((field, index) => (
                <FormInput
                  key={index}
                  field={field}
                  disabled={false}
                  register={register}
                  errorExists={!isEmpty((errors as any)[field.name])}
                  errorText={(errors as any)[field.name]?.message ?? ''}
                  isDirty={!!(dirtyFields as any)[field.name]}
                  variant="outlined"
                  {...field.inputProps}
                />
              ))}
              <Box className={classes.inputGroup}>
                <Box justifyContent={'space-between'} display={'flex'} mb={1}>
                  <Typography variant="subtitle1">NGL Composition</Typography>
                  <Typography variant="subtitle1">Mole Fraction</Typography>
                </Box>
                {secondColumn.map((field, index) => (
                  <FormInput
                    key={index}
                    field={field}
                    disabled={false}
                    register={register}
                    errorExists={!isEmpty((errors as any)[field.name])}
                    errorText={(errors as any)[field.name]?.message ?? ''}
                    isDirty={!!(dirtyFields as any)[field.name]}
                    variant="outlined"
                    {...field.inputProps}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6} mt={5}>
              <Box>
                <FileUpload
                  dropzoneText={t('file.upload.dropOrClick')}
                  heading={t(
                    'project.register.carbonContent.uploadSalesReceipt'
                  )}
                  apiUploadFunction={uploadFunction}
                  onChange={onChange}
                  wrapperProps={{ ['data-cy']: 'oilSalesReceipt' }}
                />
                <FileUpload
                  dropzoneText={t('file.upload.dropOrClick')}
                  heading={t(
                    'project.register.carbonContent.uploadGasAnalysisReport'
                  )}
                  apiUploadFunction={uploadFunction}
                  onChange={onChange}
                  wrapperProps={{ ['data-cy']: 'gasAnalysisReport' }}
                />
              </Box>
              {gasShrinkageField && (
                <FormInput
                  field={gasShrinkageField}
                  disabled={false}
                  register={register}
                  errorExists={
                    !isEmpty((errors as any)[gasShrinkageField.name])
                  }
                  errorText={
                    (errors as any)[gasShrinkageField.name]?.message ?? ''
                  }
                  isDirty={!!(dirtyFields as any)[gasShrinkageField.name]}
                  variant="outlined"
                  {...gasShrinkageField.inputProps}
                />
              )}
            </Grid>
          </Grid>
          <ButtonsGroup secondaryButtons={secondaryButtons} loading={loading} />
        </Grid>
      </form>
    </Box>
  );
};
